.gender-select {
    width: auto;
    padding: 6px 5px;
    font-weight: 400;
    outline: none;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
}

.rs-picker-menu {
    margin-left: -155px;
}

.rs-picker-toggle-read-only {
    opacity: 1;
}

.my-date-range-picker .rs-picker-toggle.rs-picker-toggle-active, .my-date-range-picker .rs-picker-toggle:hover {
    outline: none !important;
    box-shadow: none !important;
    border-color: rgb(237, 58, 120) !important;
}