.td-user-avatar-name-wrapper {
    width: 100%;
    display: flex;
    padding: 5px 0;
    margin-right: 20px;
    flex-direction: row;
    align-items: center;
}

.admin-user {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0px 0px;
}

.table thead th, .table tfoot th {
    text-transform: none;
}

.aabbb {
    width: 30px !important;
}

::placeholder {
    color: #aaa;
    font-weight: normal;
}

.container {
    /* padding: 10px; */
    max-width: none;
    /* background-color: red; */
}

.table > :not(caption) > * > * {
    padding: 0.5rem 9px;
;
}

.table:not(.table-dark):not(.table-light) thead:not(.table-dark) th, .table:not(.table-dark):not(.table-light) tfoot:not(.table-dark) th {
    background-color: white;
    /* margin-left: -30px; */
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 10px;
}

body {
    font-family: "Montserrat", Helvetica, Arial, serif;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    /* color: #6e6b7b !important; */
    /* word-break: break-word; */
}